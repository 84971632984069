import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function LoadingBar() {
  const { loading } = useSelector((state) => state.settings);

  if (!loading) return null;

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        height: 5,
        zIndex: 1500,
      }}
    >
      <LinearProgress />
    </Box>
  );
}

export default LoadingBar;
