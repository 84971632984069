import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MessageBox from './MessageBox';

function CustomCardContent(props) {
  const { title, subtitle = null, children } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        maxWidth: isMobile ? theme.viewWidth.mobile : theme.viewWidth.desktop,
        display: 'flex',
        flexDirection: 'column',
        mt: 4,
        py: 4,
        px: isMobile ? 2 : 4,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderRadius: isMobile ? '0' : 1,
        boxShadow: isMobile ? 'none' : (theme) => theme.shadows[4],
      })}
    >
      <Typography
        align='left'
        variant='h4'
        gutterBottom
        sx={{ color: 'primary.main' }}
      >
        {title}
      </Typography>
      {subtitle}
      <Box sx={{ mb: 2 }} />
      <MessageBox />
      {children}
    </Box>
  );
}

export default CustomCardContent;
