import React from 'react';

import TextField from '@mui/material/TextField';

function CustomTextField(props) {
  const {
    id,
    label,
    value,
    onChange,
    icon = null,
    autoComplete = null,
    type = 'text',
    inputProps = null,
  } = props;

  return (
    <TextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
      variant='outlined'
      sx={{ width: '100%', my: 1 }}
      type={type}
      slotProps={{
        input: {
          startAdornment: icon,
          ...inputProps,
        },
      }}
    />
  );
}

export default CustomTextField;
