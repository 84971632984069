import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import startCase from 'lodash/startCase';

import ListIcon from '@mui/icons-material/List';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';

import { ROUTES } from '../constants/routes';
import { setLoading, setMessage } from '../store/settings';

function AdminFab() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, message } = useSelector((state) => state.settings);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    handleClose();
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleRouteClick = (e) => {
    e.preventDefault();
    let navigateUrl = `/${e.target.id}`;
    const clientId = searchParams.get('client_id');
    if (clientId) {
      navigateUrl += `?client_id=${clientId}`;
    }

    navigate(navigateUrl);
    handleMouseLeave();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoadingClick = (e) => {
    e.preventDefault();
    dispatch(setLoading(!loading));
    handleMouseLeave();
  };

  const handleMessage = (e, type) => {
    e.preventDefault();
    let text = message.text
      ? ''
      : 'Message content lorem ipsum blah blah skjha. lorem ipsum esp carta dunlius sdqw';
    dispatch(setMessage({ type, text }));
    handleMouseLeave();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'fixed',
        bottom: 16,
        left: 16,
        height: 75,
        width: 75,
      }}
    >
      {isHovering && (
        <Fab
          color='primary'
          onClick={handleClick}
          sx={{
            zIndex: 9999,
            position: 'fixed',
            bottom: 16,
            left: 16,
            boxShadow: 'none',
          }}
        >
          <ListIcon />
        </Fab>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Stack
          direction='column'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
        >
          {ROUTES.map((route) => (
            <Button
              id={route}
              key={route}
              onClick={handleRouteClick}
              sx={{ textTransform: 'none' }}
            >
              {startCase(route)}
            </Button>
          ))}
          <Button
            key='loading'
            onClick={handleLoadingClick}
            sx={{ textTransform: 'none' }}
          >
            Toggle Loading
          </Button>
          <Button
            key='successMessage'
            onClick={(e) => handleMessage(e, 'success')}
            sx={{ textTransform: 'none' }}
          >
            Toggle Success Message
          </Button>
          <Button
            key='errorMessage'
            onClick={(e) => handleMessage(e, 'error')}
            sx={{ textTransform: 'none' }}
          >
            Toggle Error Message
          </Button>
        </Stack>
      </Popover>
    </div>
  );
}

export default AdminFab;
