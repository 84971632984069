import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { forgotPassword } from '../../../helpers/cognito';
import { CONFIRM_FORGOT_PASSWORD, LOG_IN } from '../../../constants/routes';
import { setLoading, setMessage } from '../../../store/settings';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import TextWithLink from '../../../components/TextWithLink';
import CustomTextField from '../../../components/CustomTextField';

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const client = useSelector((state) => state.client);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setMessage({ type: '', text: '' }));
    dispatch(setLoading(true));
    const username = email.toLowerCase();

    try {
      await forgotPassword(username, client);
      dispatch(
        push(
          `${CONFIRM_FORGOT_PASSWORD}?client_id=${client.clientId}&username=${username}`
        )
      );
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent
      title='Forgot Password'
      subtitle={
        <Typography variant='body1'>
          Enter your email address below and we will send a message to reset
          your password
        </Typography>
      }
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CustomTextField
            id='forgotPassword-email'
            label='Email'
            value={email}
            autoComplete='current-email'
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            icon={
              <InputAdornment position='start'>
                <EmailIcon />
              </InputAdornment>
            }
          />
          <CustomButton onClick={handleSubmit}>Reset Password</CustomButton>
          <TextWithLink
            text='Back to'
            linkText='Log In'
            onClick={() => navigate(`/${LOG_IN}?client_id=${client.clientId}`)}
          />
        </Box>
      </form>
    </CustomCardContent>
  );
}

export default ForgotPassword;
