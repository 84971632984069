import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LOG_IN } from '../../../constants/routes';
import { confirmForgotPassword } from '../../../helpers/cognito';
import { setMessage, setLoading } from '../../../store/settings';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomTextField from '../../../components/CustomTextField';
import CustomButton from '../../../components/CustomButton';

function ConfirmForgotPassword() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const client = useSelector((state) => state.client);

  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  useEffect(() => {
    const _username = searchParams.get('username');
    if (_username) {
      setUsername(_username);
    } else {
      setUsername('');
    }
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    try {
      await confirmForgotPassword(username, password, code, client);
      dispatch(setMessage({ type: 'success', text: `Password changed` }));
      dispatch(push(`${LOG_IN}?client_id=${client.clientId}`));
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent
      title='Confirm Forgot Password'
      subtitle={
        <Typography variant='body1'>
          We have sent a password reset code by email to{' '}
          <strong>{username}</strong>.<br />
          Enter it below to reset your password.
        </Typography>
      }
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CustomTextField
            id='code'
            label='Code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <CustomTextField
            id='new-password'
            label='New Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={
              <InputAdornment
                position='start'
                onClick={() => setHidePassword((h) => !h)}
              >
                {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </InputAdornment>
            }
            type={hidePassword ? 'password' : 'text'}
          />
        </Box>
        <CustomButton onClick={handleSubmit}>Change Password</CustomButton>
      </form>
    </CustomCardContent>
  );
}

export default ConfirmForgotPassword;
