import React from 'react';
import { useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

function CustomButton(props) {
  const {
    onClick,
    variant = 'contained',
    color = 'primary',
    children,
    disabled = false,
  } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const loading = useSelector((state) => state.settings.loading);

  return (
    <Button
      disabled={disabled || loading}
      type='submit'
      variant={variant}
      onClick={onClick}
      color={color}
      size={isMobile ? 'large' : 'medium'}
      sx={{
        width: '100%',
        borderRadius: 1,
        my: 1,
        textTransform: 'none',
      }}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
