import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useTheme from '@mui/material/styles/useTheme';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function MessageBox() {
  const theme = useTheme();
  const { type = 'error', text } = useSelector(
    (state) => state.settings.message
  );

  const GREEN = theme.veregy.colors.green;
  const RED = theme.veregy.colors.red;

  const [color, setColor] = useState(RED);

  useEffect(() => {
    if (type === 'success') setColor(GREEN);
    else setColor(RED);
  }, [type, GREEN, RED]);

  if (!text) return null;
  return (
    <Box sx={{ width: '100%', mb: 1 }}>
      <Typography
        align='center'
        color='white'
        sx={{
          border: `1px solid ${color}`,
          backgroundColor: alpha(color, 0.95),
          borderRadius: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default MessageBox;
