import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { respondToAuthChallenge } from '../../../helpers/cognito';
import { setMessage, setLoading } from '../../../store/settings';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomTextField from '../../../components/CustomTextField';

function SetPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    if (password !== confirmPassword) {
      dispatch(setMessage({ type: 'error', text: "password don't match" }));
      dispatch(setLoading(false));
      return;
    }

    try {
      const { refreshToken } = await respondToAuthChallenge(
        location.state.ChallengeName,
        {
          USERNAME: location.state.ChallengeParameters.USER_ID_FOR_SRP,
          NEW_PASSWORD: password,
        },
        location.state.Session,
        client
      );
      window.location.assign(
        `${client.signInUrl}?refreshToken=${refreshToken}`
      );
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent
      title='Set Password'
      subtitle={
        <Typography variant='body1'>
          Enter a valid password to finish setting up your account
        </Typography>
      }
    >
      <form onSubmit={handleSubmit}>
        <CustomTextField
          id='new-password'
          label='New Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          icon={
            <InputAdornment
              position='start'
              onClick={() => setHidePassword((h) => !h)}
            >
              {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </InputAdornment>
          }
          type={hidePassword ? 'password' : 'text'}
        />
        <CustomTextField
          id='confirm-password'
          label='Re-enter Password'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          icon={
            <InputAdornment
              position='start'
              onClick={() => setHidePassword((h) => !h)}
            >
              {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </InputAdornment>
          }
          type={hidePassword ? 'password' : 'text'}
        />
        <CustomButton onClick={handleSubmit}>Set Password</CustomButton>
      </form>
    </CustomCardContent>
  );
}

export default SetPassword;
