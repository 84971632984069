import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { useNavigate } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { signUp } from '../../../helpers/cognito';
import { setLoading, setMessage } from '../../../store/settings';
import { CONFIRM_SIGN_UP, LOG_IN } from '../../../constants/routes';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomTextField from '../../../components/CustomTextField';
import GoogleButton from '../../../components/GoogleButton';

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector((state) => state.client);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));
    const username = email.toLowerCase();

    try {
      await signUp(username, password, name, client);
      dispatch(
        push(
          `/${CONFIRM_SIGN_UP}?client_id=${client.clientId}&username=${username}`
        )
      );
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent title='Create an Orchestrate Account'>
      <form onSubmit={handleSubmit}>
        <CustomTextField
          id='signup-name'
          label='Name'
          value={name}
          autoComplete='current-name'
          onChange={(e) => setName(e.target.value)}
          icon={
            <InputAdornment position='start'>
              <AccountCircleIcon />
            </InputAdornment>
          }
        />
        <CustomTextField
          id='signup-email'
          label='Email'
          value={email}
          autoComplete='current-email'
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          icon={
            <InputAdornment position='start'>
              <EmailIcon />
            </InputAdornment>
          }
        />
        <CustomTextField
          id='signup-password'
          label='Password'
          value={password}
          autoComplete='current-password'
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          icon={
            <InputAdornment position='start'>
              <LockIcon />
            </InputAdornment>
          }
        />
        <CustomButton onClick={handleSubmit}>Sign Up</CustomButton>
        <Typography
          variant='body2'
          color='textSecondary'
          align='center'
          sx={{ width: '100%', mt: 1 }}
        >
          Already have an account?
        </Typography>
        <CustomButton
          variant='outlined'
          onClick={() => navigate(`/${LOG_IN}?client_id=${client.clientId}`)}
        >
          Log In
        </CustomButton>
        <GoogleButton action='sign up' />
      </form>
    </CustomCardContent>
  );
}

export default SignUp;
