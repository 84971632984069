import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function TextWithLink(props) {
  const { text, linkText, onClick } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        width: '100%',
      }}
    >
      <Typography variant='body2' color='textSecondary'>
        {text}
      </Typography>
      <Link underline='hover' onClick={onClick} variant='body2'>
        {linkText}
      </Link>
    </Box>
  );
}

export default TextWithLink;
