import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import { authWithGoogle } from '../helpers/auth';

function GoogleButton(props) {
  const { action } = props;
  const client = useSelector((state) => state.client);
  const loading = useSelector((state) => state.settings.loading);

  const handleGoogleButtonClick = async (e) => {
    e.preventDefault();
    try {
      await authWithGoogle(client);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <Divider sx={{ mt: 2, width: '100%' }}>
        <Typography
          align='center'
          variant='body2'
          color='textSecondary'
          sx={{ px: 2 }}
        >
          or {action} using
        </Typography>
      </Divider>

      <Button
        sx={{ mt: 2, width: '100%', textTransform: 'none' }}
        variant='outlined'
        disabled={loading}
        startIcon={
          <Icon sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <img
              src='/google_logo.svg'
              alt='google'
              style={{ height: '100%' }}
            />
          </Icon>
        }
        onClick={handleGoogleButtonClick}
      >
        Google
      </Button>
    </>
  );
}

export default GoogleButton;
